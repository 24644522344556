import React, { useState } from "react";
import { Container, List, Segment, Image, Icon } from 'semantic-ui-react';
import { useViewport } from "@standards/hooks";
import './Footer.less';

const Footer = () => {
    const { isMobile } = useViewport();
    const [isAboutCollapsed, setIsAboutCollapsed] = useState(true);
    const [isSecondAboutCollapsed, setIsSecondAboutCollapsed] = useState(true);
    const [isNavtejCollapsed, setIsNavtejCollapsed] = useState(true);
    const [isAdditionalResourcesCollapsed, setIsAdditionalResourcesCollapsed] = useState(true);

    const toggleAboutCollapse = () => {
        setIsAboutCollapsed(!isAboutCollapsed);
    };

    const toggleSecondAboutCollapse = () => {
        setIsSecondAboutCollapsed(!isSecondAboutCollapsed);
    };

    const toggleNavtejCollapse = () => {
        setIsNavtejCollapsed(!isNavtejCollapsed);
    };

    const toggleAdditionalResourcesCollapse = () => {
        setIsAdditionalResourcesCollapsed(!isAdditionalResourcesCollapsed);
    };

    const mobileFooterHeader = {
        color: "white",
        fontSize: 14,
        padding: 10,
        marginRight: 25,
        fontWeight: "bold",
    };

    const mobileFooterItems = {
        color: "white",
        fontSize: 14,
        padding: 10,
        marginLeft: 15,
    };

    const mobileFooterContactDetails = {
        color: "white",
        fontSize: 14,
        marginLeft: 10,
        display: "flex",
        flexDirection: "column",
    };

    const mobileFooterContactDetailsFirst = {
        color: "white",
        fontSize: 14,
        marginTop: 10,
        marginLeft: 10,
        display: "flex",
        flexDirection: "column",
    };

    const mobileFooterSegment2 = {
        background: "#242424",
        color: "white",
    };

    const MobileView = () => (
        <>
            <div id="footer-mobile" style={{ background: "#242424" }}>
                <List.Item>
                    <List.Header>
                        <Image src='/doe-logo-energy-1.png' />
                    </List.Header>
                    <List.Content>
                        <List.Item className="doeaddress">
                            <span style={mobileFooterContactDetailsFirst}>1000 Independence Ave. SW</span>
                            <span style={mobileFooterContactDetails}>Washington DC 20585</span>
                            <span style={mobileFooterContactDetails}>202-586-5000</span>
                        </List.Item>
                        <List.Item style={mobileFooterHeader}>
                            <Icon name='mail' size='large' style={{ color: "white" }} />
                            <a href="/email-notification" target="_blank" style={{ color: "white", textDecoration: "none" }}>Sign Up for Email Updates</a>
                        </List.Item>
                    </List.Content>
                </List.Item>

                <List.Item>
                    <List.Header onClick={toggleAboutCollapse} id="DropDown" style={mobileFooterHeader}>
                        {isAboutCollapsed ? (
                            <Icon name='plus' size='large' style={{ color: "white !important" }} />
                        ) : (
                            <Icon name="minus" size='large' style={{ color: "white !important" }} />
                        )}
                        ABOUT ENERGY.GOV
                    </List.Header>

                    {!isAboutCollapsed && (
                        <List.Content style={mobileFooterItems}>

                            <List.Item><a href="https://www.energy.gov/ehss/about-us" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>History</a></List.Item>
                            <List.Item><a href="https://www.energy.gov/ehss/organizational-chart" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>Leadership</a></List.Item>
                            <List.Item><a href="https://www.energy.gov/ehss/listings/news-archive" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>News</a></List.Item>
                            <List.Item><a href="https://www.energy.gov/doe-stem/doe-stem" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>Science Education</a></List.Item>
                            <List.Item><a href="https://www.energy.gov/careers/jobs" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>Careers & Internships</a></List.Item>
                            <List.Item><a href="/contact-us" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>Contact Us</a></List.Item>
                            <List.Item><a href="/sitemap" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>Site Map</a></List.Item>

                        </List.Content>
                    )}

                    <List.Header onClick={toggleSecondAboutCollapse} id="DropDown" style={mobileFooterHeader}>

                        {isSecondAboutCollapsed ? (
                            <Icon name='plus' size='large' style={{ color: "white !important" }} />
                        ) : (
                            <Icon name="minus" size='large' style={{ color: "white !important" }} />
                        )}
                        ENERGY.GOV RESOURCES
                    </List.Header>
                    {!isSecondAboutCollapsed && (
                        <List.Content style={mobileFooterItems}>
                            <List.Item><a href="https://www.energy.gov/budget-performance" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>Budget & Performance</a></List.Item>

                            <List.Item><a href="https://www.energy.gov/management/freedom-information-act" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>FOIA</a></List.Item>
                            <List.Item><a href="https://www.energy.gov/ig/office-inspector-general" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>Inspector General</a></List.Item>
                            <List.Item><a href="https://www.energy.gov/cio/privacy-program" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>Privacy Program</a></List.Item>
                            <List.Item><a href="https://www.energy.gov/justice/civil-rights" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>Office of Civil Rights</a></List.Item>

                        </List.Content>
                    )}

                    <List.Header onClick={toggleNavtejCollapse} id="DropDown" style={mobileFooterHeader} >
                        {isNavtejCollapsed ? (
                            <Icon name='plus' size='large' style={{ color: "white !important" }} />
                        ) : (
                            <Icon name="minus" size='large' style={{ color: "white !important" }} />
                        )}
                        FEDERAL GOVERNMENT
                    </List.Header>
                    {!isNavtejCollapsed && (
                        <List.Content style={mobileFooterItems}>
                            {/* <List.Item>The White House</List.Item>
                            <List.Item>USA.gov</List.Item> */}

                            <List.Item><a href="https://www.whitehouse.gov/" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>The White House</a></List.Item>
                            <List.Item><a href="https://www.energy.gov/" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>DOE.gov</a></List.Item>
                            <List.Item><a href="https://www.usa.gov/" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>USA.gov</a></List.Item>

                        </List.Content>
                    )}



                    <List.Header onClick={toggleAdditionalResourcesCollapse} id="DropDown" style={mobileFooterHeader}>

                        {isAdditionalResourcesCollapsed ? (
                            <Icon name='plus' size='large' style={{ color: "white !important" }} />
                        ) : (
                            <Icon name="minus" size='large' style={{ color: "white !important" }} />
                        )}
                        ADDITIONAL RESOURCES
                    </List.Header>
                    {!isAdditionalResourcesCollapsed && (
                        <List.Content style={mobileFooterItems}>
                            <List.Item><a href="https://www.energy.gov/web-policies" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>Web Policies</a></List.Item>
                            <List.Item><a href="" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>Inviting Friends</a></List.Item>
                            <List.Item><a href="https://www.energy.gov/diversity/no-fear-act-data" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>No Fear Act</a></List.Item>
                            <List.Item><a href="https://www.energy.gov/whistleblower-protection-and-nondisclosure-agreements" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>Whistleblower Protection</a></List.Item>
                            <List.Item><a href="https://www.energy.gov/diversity/notice-equal-employment-opportunity-eeo-findings-discrimination-harassment-andor" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>Notice of EEO Findings of Discrimination</a></List.Item>
                            <List.Item><a href="https://www.energy.gov/cio/department-energy-information-quality-guidelines" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>Information Quality</a></List.Item>
                            <List.Item><a href="https://www.energy.gov/open-government" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>Open Gov</a></List.Item>

                        </List.Content>
                    )}
                </List.Item>

                {/* <Container>
                    <List horizontal style={mobileFooterSegment2}>
                        <List.Item>Web Policies</List.Item>
                        <List.Item>Inviting Friends</List.Item>
                        <List.Item>No Fear Act</List.Item>
                        <List.Item>Whistleblower Protection</List.Item>
                        <List.Item>Notice of EEO Findings of Discrimination</List.Item>
                        <List.Item>Information Quality</List.Item>
                        <List.Item>Open Gov</List.Item>
                    </List>
                </Container> */}
            </div>
        </>

    );

    const DesktopView = () => (
        <div id="footer-wrapper">
            <Segment
                role="contentinfo"
                vertical
                padded
                textAlign="center"
                className="segment-1"
            >
                <Container>
                    <List
                        horizontal
                        className={isMobile ? "footer mobile" : "footer"}
                    >
                        <List.Item>
                            <List.Header>
                                <a href="https://www.energy.gov/">
                                    <Image src='/doe-logo-energy-1.png' />
                                </a>
                            </List.Header>
                            <List.Content>
                                <List.Item className="doeaddress">
                                    <span>1000 Independence Ave. SW</span>
                                    <span>Washington DC 20585</span>
                                    <span>202-586-5000</span>
                                </List.Item>
                                <List.Item>
                                    <Icon name='mail' size='large' />
                                    <a href="/email-notification" target="_blank" style={{ color: "white" }}>Sign Up for Email Updates</a>
                                </List.Item>
                            </List.Content>
                        </List.Item>

                        <List.Item>
                            <List.Header>ABOUT ENERGY.GOV</List.Header>
                            <List.Content>
                                <List.Item><a href="https://www.energy.gov/ehss/about-us" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>History</a></List.Item>
                                <List.Item><a href="https://www.energy.gov/ehss/organizational-chart" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>Leadership</a></List.Item>
                                <List.Item><a href="https://www.energy.gov/ehss/listings/news-archive" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>News</a></List.Item>
                                <List.Item><a href="https://www.energy.gov/doe-stem/doe-stem" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>Science Education</a></List.Item>
                                <List.Item><a href="https://www.energy.gov/careers/jobs" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>Careers & Internships</a></List.Item>
                                <List.Item><a href="/contact-us" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>Contact Us</a></List.Item>
                                <List.Item><a href="/sitemap" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>Site Map</a></List.Item>
                            </List.Content>
                        </List.Item>

                        <List.Item>
                            <List.Header>ENERGY.GOV RESOURCES</List.Header>
                            <List.Content>
                                <List.Item><a href="https://www.energy.gov/budget-performance" style={{ color: "#fff", textDecoration: "none" }}>Budget & Performance</a></List.Item>

                                <List.Item><a href="https://www.energy.gov/management/freedom-information-act" style={{ color: "#fff", textDecoration: "none" }}>FOIA</a></List.Item>
                                <List.Item><a href="https://www.energy.gov/ig/office-inspector-general" style={{ color: "#fff", textDecoration: "none" }}>Inspector General</a></List.Item>
                                <List.Item><a href="https://www.energy.gov/cio/privacy-program" style={{ color: "#fff", textDecoration: "none" }}>Privacy Program</a></List.Item>
                                <List.Item><a href="https://www.energy.gov/justice/civil-rights" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>Office of Civil Rights</a></List.Item>
                            </List.Content>
                        </List.Item>

                        <List.Item>
                            <List.Header>FEDERAL GOVERNMENT</List.Header>
                            <List.Content>
                                <List.Item><a href="https://www.whitehouse.gov/" style={{ color: "#fff", textDecoration: "none" }}>The White House</a></List.Item>
                                <List.Item><a href="https://www.energy.gov/" style={{ color: "#fff", textDecoration: "none" }}>DOE.gov</a></List.Item>
                                <List.Item><a href="https://www.usa.gov/" style={{ color: "#fff", textDecoration: "none" }}>USA.gov</a></List.Item>
                            </List.Content>
                        </List.Item>

                    </List>
                </Container>
            </Segment>
            <Segment className="segment-2">
                <Container>
                    <List horizontal>
                        <List.Item as="a" href="https://www.energy.gov/web-policies">Web Policies</List.Item>
                        <List.Item as="a" href="https://doe.responsibledisclosure.com/hc/en-us">Vulnerability Disclosure Program</List.Item>
                        <List.Item as="a" href="https://www.energy.gov/justice/no-fear-act-data">No Fear Act</List.Item>
                        <List.Item as="a" href="https://www.energy.gov/whistleblower-protection-and-nondisclosure-agreements">Whistleblower Protection</List.Item>
                        <List.Item as="a" href="https://www.energy.gov/justice/notice-equal-employment-opportunity-eeo-findings-discrimination-harassment-andor">Notice of EEO Findings of Discrimination</List.Item>
                        <List.Item as="a" href="https://www.energy.gov/cio/department-energy-information-quality-guidelines">Information Quality</List.Item>
                        <List.Item as="a" href="https://www.energy.gov/open-government">Open Gov</List.Item>
                    </List>
                </Container>
            </Segment>
        </div>
    );

    return isMobile ? <MobileView /> : <DesktopView />;
};

export default Footer;